export default function (max = 10) {
    return {
        data() {
            return {
                current: 0
            }
        },
        created() {
            this.run()
        },
        methods: {
            run() {
                const step = () => {
                    window.requestAnimationFrame(() => {
                        if (this.current++ < max) {
                            step()
                        }
                    })
                }
                step()
            },
            delay(sort) {
                return this.current >= sort
            }
        }
    }
}
