// 默认请求异常提示
export const ERROR_DEFAULT_HTTP_REQUEST_FAIL = '请求失败'

// message提示
export const ERROR_COMMIT_SUCCESS = '提交成功'
export const ERROR_CACHE_SUCCESS = '暂存成功'
export const ERROR_COMMIT_EDIT = '修改成功'
export const ERROR_COMMIT_DELETE = '删除成功'
export const ERROR_COMMIT_OPEN = '已启用'
export const ERROR_COMMIT_CLOSE = '已禁用'
export const ERROR_FORWARD_SUCCESS = '转发成功'
export const ERROR_ASSIGN_SUCCESS = '指派成功'
export const ERROR_COMMIT_CLOSE_ORDER = '关闭成功'
export const ERROR_COMMIT_SETTING = '设置成功'
export const ERROR_COPY_SUCCESS = '复制成功'


