import store from '@store'
import router from './index'
import { WHITE_LIST } from '@config'
import { getToken } from '@utils/auth'

router.beforeEach(async (to, from, next) => {
    window.NProgress.start() // 进度条开始

    // 是否在免鉴权列表内
    // 是否已登录
    if (getToken()) {
        if (to.path === '/login') {
            next({
                path: '/'
            })
            window.NProgress.done()
        } else {
            // 是否已获取到用户权限列表
            if (!store.getters.userInfo) {
                // 如果没有 则 进去权限获取请求
                try {
                    // dispatch 权限
                    await store.dispatch('getUserInfo')
                    // dispatah 路由
                    let routes = await store.dispatch('generateRoutes')
                    // 添加404
                    routes = [
                        ...routes, {
                            path: '*',
                            redirect: '/404',
                            hidden: true
                        }
                    ]
                    // 向路由表中 添加动态路由
                    router.addRoutes(routes)
                    // 跳转至目标
                    next({
                        ...to,
                        replace: true
                    })
                } catch (e) {
                    store.dispatch('logOut')
                    // notification.error({
                    //     message: '错误',
                    //     description: '请求用户信息失败，请重试'
                    // })
                    // setTimeout(()=>{
                    //
                    // },500)
                    throw new Error(e)
                }
            } else {
                next()
            }
        }
    } else {
        if (WHITE_LIST.indexOf(to.path) !== -1) {
            next()
        } else {
            // 跳转至登录页
            next(`/login?redirect=${to.path}`)
            window.NProgress.done()
        }
    }

})

router.afterEach(() => {
    window.NProgress.done()
})
