/**
 * 鉴权指令
 * @param Vue
 * @param options
 */

export default {
    // eslint-disable-next-line no-unused-vars
    inserted(el, bind, vNode) {
        // 获取 按钮权限
        /* const buttonRole = vNode.context.$store.getters.buttonRole || {}
        // 获取按钮值
        const roleName = bind.value
        // 判断是否有权限
        if (!buttonRole[roleName]) {
            el.remove()
        }*/
    }
}
