<template>
    <layout-sider v-model="collapsed"
                  :class="layoutSideClassName"
                  :width="sidebarWidth"
                  :collapsible="collapsible"
                  :collapsed-width="sidebarCollapsedWidth"
                  :trigger="null">
        <i-menu :theme="theme"
                :collapsed="collapsed"
                :menu-data="menuData"
                @select="onSelect" />
        <div class="gongbao-sider__footer">
            <!-- 展开/关闭按钮 -->
            <a-icon class="gongbao-sider__trigger"
                    :type="collapsed ? 'menu-unfold' : 'menu-fold'"
                    @click="toggleCollapse" />
        </div>
    </layout-sider>
</template>

<script>
import { Layout, Icon as AIcon } from 'ant-design-vue'
import IMenu from './menu'
import { sidebarWidth, sidebarCollapsedWidth } from '@assets/styles/varibles.scss'

export default {
    name: 'SiderMenu',
    components: {
        IMenu,
        AIcon,
        LayoutSider: Layout.Sider
    },
    props: {
        collapsible: {
            type: Boolean,
            required: false,
            default: false
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        menuData: {
            type: Array,
            required: true
        },
        theme: {
            type: String,
            required: false,
            default: 'light'
        },
        fixed: {
            type: Boolean,
            default: false
        },
        isMobile: {
            type: Boolean,
            default: false
        },
        systemName: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            sidebarWidth,
            sidebarCollapsedWidth
        }
    },
    computed: {
        layoutSideClassName() {
            const { theme, fixed } = this
            return [theme, 'gongbao-sider', fixed ? 'gongbao-sider--fixed' : null]
        }
    },
    methods: {
        onSelect(obj) {
            this.$emit('menu-select', obj)
        },
        toggleCollapse() {
            this.$emit('toggle-collapse')
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.gongbao-sider {
  z-index: 8;

  &.light {
    background-color: #fff;
  }

  &.dark {
    background-color: #001529;
  }

  &--fixed {
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    border-right: none;
    box-shadow: 0 2px 30px 0 rgba(220, 222, 223, 0.5);

    .ant-menu-root {
      height: calc(100% - 32px);
      overflow: hidden;
      overflow-y: auto;
    }
  }

  &__footer {
    border-top: 1px solid #f0f0f0;
    padding: 6px 22px;
  }

  &__icon {
    &.anticon {
      vertical-align: 0;

      &:before {
        display: inline-block;
      }
    }
  }

  .ant-menu-inline-collapsed {
    width: $sidebar-collapsed-width;

    & > .ant-menu-item,
    & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item,
    & > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-submenu > .ant-menu-submenu-title,
    & > .ant-menu-submenu > .ant-menu-submenu-title {
      padding: 0 22px !important;
    }
  }

  .ant-menu-inline,
 .ant-menu-vertical,
 .ant-menu-vertical-left {
    border-right: none;
  }
}
</style>
