/**
 * 风控采购
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    RISK_CONTROL_PROCUREMENT_MENU_CODE,
    RISK_CONTROL_PROCUREMENT_ORDER_LIST_MENU_CODE,
    RISK_CONTROL_PROCUREMENT_ORDER_INFO_MENU_CODE,
    RISK_CONTROL_PROCUREMENT_IMPORT_UNDER_MENU_CODE,
    RISK_CONTROL_PROCUREMENT_PROCUREMENT_LIST_MENU_CODE,
    RISK_CONTROL_PROCUREMENT_SUBMIT_ORDER_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        name: 'RiskControlProcurement',
        path: '/risk-control-procurement',
        redirect: '/risk-control-procurement/procurement-list/index',
        component: BasicLayout,
        code: RISK_CONTROL_PROCUREMENT_MENU_CODE,
        sort: 2,
        meta: {
            title: '风控采购',
            icon: 'report',
            iconType: 'iconfont'
        },
        children: [
            {
                name: 'ProcurementListPage',
                path: 'procurement-list',
                component: RouteLayout,
                code: RISK_CONTROL_PROCUREMENT_PROCUREMENT_LIST_MENU_CODE,
                redirect: '/risk-control-procurement/procurement-list/index',
                meta: {
                    title: '风控采购'
                },
                children: [
                    {
                        name: 'ProcurementList',
                        path: 'index',
                        component: () => import('./ProcurementList'),
                        code: RISK_CONTROL_PROCUREMENT_PROCUREMENT_LIST_MENU_CODE,
                        meta: {
                            title: '风控采购',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        name: 'SubmitOrder',
                        path: 'submit-order',
                        component: () => import('./SubmitOrder'),
                        code: RISK_CONTROL_PROCUREMENT_SUBMIT_ORDER_MENU_CODE,
                        meta: {
                            title: '提交订单',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            }, {
                name: 'OrderListPage',
                path: 'order-list',
                component: RouteLayout,
                code: RISK_CONTROL_PROCUREMENT_ORDER_LIST_MENU_CODE,
                redirect: '/risk-control-procurement/order-list/index',
                meta: {
                    title: '全部订单'
                },
                children: [
                    {
                        name: 'ProcurementOrderList',
                        path: 'index',
                        component: () => import('./OrderList'),
                        code: RISK_CONTROL_PROCUREMENT_ORDER_LIST_MENU_CODE,
                        meta: {
                            title: '全部订单',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        name: 'OrderInfo',
                        path: 'order-info',
                        component: () => import('./OrderInfo'),
                        code: RISK_CONTROL_PROCUREMENT_ORDER_INFO_MENU_CODE,
                        meta: {
                            title: '订单详情'
                        },
                        hidden: true
                    }, {
                        name: 'ImportUnderwrite',
                        path: 'import-underwrite',
                        component: () => import('./ImportUnderwrite'),
                        code: RISK_CONTROL_PROCUREMENT_IMPORT_UNDER_MENU_CODE,
                        meta: {
                            title: '导入承保结果'
                        },
                        hidden: true
                    }
                ]
            }
        ]
    }
]

export default routes
