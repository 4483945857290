import { Menu, Icon } from 'ant-design-vue'

import { resolvePath, hasOneShowingChild } from '@utils/menu'

import { isArray, isExternal } from '@utils'

const { Item, SubMenu } = Menu


export default {
    name: 'IMenu',
    props: {
        menuData: {
            type: Array,
            required: true
        },
        theme: {
            type: String,
            required: false,
            default: 'dark'
        },
        mode: {
            type: String,
            required: false,
            default: 'inline'
        },
        collapsed: {
            type: Boolean,
            required: false,
            default: false
        },
        // 合并菜单，是否在只有一个子菜单时合并子菜单
        merge: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            openKeys: [],
            selectedKeys: [],
            cachedOpenKeys: []
        }
    },
    computed: {
        rootSubmenuKeys: vm => {
            let keys = []
            vm.menuData.forEach(item => {
                keys.push(item.path)
            })
            return keys
        }
    },
    created() {
        this.updateMenu()
    },
    watch: {
        collapsed(val) {
            if (val) {
                this.cachedOpenKeys = this.openKeys
                this.openKeys = []
            } else {
                this.openKeys = this.cachedOpenKeys
            }
        },
        $route() {
            this.updateMenu()
        }
    },
    methods: {
        renderIcon(h, icon, meta) {
            const renderFunc = {
                iconfont: icon => h('i', {
                    class: [
                        'gongbao-sider__icon',
                        'anticon',
                        'iconfont',
                        `icon-${icon}`
                    ]
                }),
                default: icon => h(
                    Icon,
                    {
                        props: {
                            type: icon
                        }
                    }
                )
            }
            if (icon) {
                const { iconType } = meta || {}
                const render = renderFunc[iconType] ? renderFunc[iconType] : renderFunc.default
                return render(icon)
            }

            return null

        },
        // 渲染菜单项
        renderMenuItem(h, menu, pIndex, index, basePath, parent) {

            const path = resolvePath(basePath, menu.path)
            const meta = menu.meta || {}
            let dom = null
            const icon = meta.icon || parent && parent.meta && parent.meta.icon
            // 判断是否为外部链接
            // 如果为外部链接则直接渲染为a标签
            if (isExternal(path)) {
                dom = h(
                    'a',
                    {
                        attrs: {
                            href: path
                        }
                    },
                    [this.renderIcon(h, icon, meta), h('span', [meta.title])]
                )
            } else {
                dom = h(
                    'router-link',
                    {
                        props: {
                            to: path
                        }
                    },
                    [this.renderIcon(h, icon, meta), h('span', [meta.title])]
                )
            }
            return h(
                Item,
                {
                    key: path ? path : 'item_' + pIndex + '_' + index
                },
                [dom]
            )
        },
        // 渲染子菜单
        renderSubMenu(h, menu, pIndex, index, basePath) {
            const meta = menu.meta || {}

            const subItem = [
                h(
                    'span',
                    {
                        slot: 'title'
                    },
                    [this.renderIcon(h, meta.icon, meta), h('span', [meta.title])]
                )
            ]
            const path = resolvePath(basePath, menu.path)
            const itemArr = this.eachChildrenItem(h, menu, pIndex, index, path)
            return h(
                SubMenu,
                {
                    key: path ? path : 'submenu_' + pIndex + '_' + index
                },
                subItem.concat(itemArr)
            )
        },
        // 渲染透明层菜单
        renderTransparentItem(h, menu, pIndex, index, basePath) {
            const path = resolvePath(basePath, menu.path)
            return this.eachChildrenItem(h, menu, pIndex, index, path)
        },
        renderItem(h, menu, pIndex, index, basePath) {

            /**
             * 判断渲染类型
             * @param menu
             * @returns {string}
             */
            function getMode(menu) {
                const { hidden, transparent } = menu

                // 如果设置影藏 则直接跳过渲染
                if (hidden) {
                    return 'hidden'
                }

                // 如果为透明
                // 如果没有子节点
                if (transparent) {
                    return 'transparent'
                }

                return 'default'

            }

            // 渲染模式
            const render = {
                /**
                 * 默认渲染方式
                 * @param h
                 * @param menu
                 * @param pIndex
                 * @param index
                 * @param basePath
                 * @returns {*}
                 */
                default: (h, menu, pIndex, index, basePath) => {
                    const { children } = menu
                    const hasChildren = children && children.length
                    // 没有子节点则直接渲染为menuItem
                    if (!hasChildren) {
                        return this.renderMenuItem(h, menu, pIndex, index, basePath)
                    }
                    // 获取需要渲染的子节点数量与需要渲染的子节点
                    const { child, length } = hasOneShowingChild(children)
                    if (length === 0) {
                        return this.renderMenuItem(h, menu, pIndex, index, basePath)
                    }
                    return this.merge && length === 1 ?
                        this.renderMenuItem(h, child, pIndex, index, basePath, menu) :
                        this.renderSubMenu(h, menu, pIndex, index, basePath)

                },
                /**
                 * 透明层渲染方式
                 * @param h
                 * @param menu
                 * @param pIndex
                 * @param index
                 * @param basePath
                 * @returns {[]}
                 */
                transparent: (h, menu, pIndex, index, basePath) => {
                    const { children } = menu
                    const hasChildren = children && children.length
                    if (hasChildren) {
                        return this.renderTransparentItem(h, menu, pIndex, index, basePath)
                    }
                },
                hidden: () => {
                }
            }
            const mode = getMode(menu)

            return render[mode] ? render[mode](h, menu, pIndex, index, basePath) : undefined


        },
        renderMenu(h, menuTree) {
            let menuArr = []
            menuTree.forEach((menu, i) => {
                const itemElem = this.renderItem(h, menu, '0', i, menu.path)
                if (isArray(itemElem)) {
                    menuArr = menuArr.concat(itemElem)
                } else {
                    menuArr.push(itemElem)
                }
            })
            return menuArr
        },
        /**
         * 循环遍历渲染子节点
         * @param h
         * @param menu
         * @param pIndex
         * @param index
         * @param path
         * @returns {[]}
         */
        eachChildrenItem(h, menu, pIndex, index, path) {
            let itemArr = []
            const pIndex_ = pIndex + '_' + index

            menu.children.forEach((item, i) => {
                const itemElem = this.renderItem(h, item, pIndex_, i, path)
                if (isArray(itemElem)) {
                    itemArr = itemArr.concat(itemElem)
                } else {
                    itemArr.push(itemElem)
                }
            })
            return itemArr
        },
        onOpenChange(openKeys) {
            const latestOpenKey = openKeys.find(key => this.openKeys.indexOf(key) === -1)
            if (this.rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
                this.openKeys = openKeys
            } else {
                this.openKeys = latestOpenKey ? [latestOpenKey] : []
            }
        },
        updateMenu() {
            let routes = this.$route.matched.concat()
            let openKeys = []
            routes.forEach(item => {
                openKeys.push(item.path)
            })
            this.selectedKeys = openKeys
            this.collapsed || this.mode === 'horizontal' ?
                this.cachedOpenKeys = openKeys :
                this.openKeys = openKeys
        }
    },
    render(h) {
        return h(
            Menu,
            {
                props: {
                    theme: this.$props.theme,
                    mode: this.$props.mode,
                    openKeys: this.openKeys,
                    selectedKeys: this.selectedKeys
                },
                on: {
                    openChange: this.onOpenChange,
                    select: obj => {
                        this.selectedKeys = obj.selectedKeys
                        this.$emit('select', obj)
                    }
                }
            },
            this.renderMenu(h, this.menuData)
        )
    }
}
