<template>
    <a-locale-provider :locale="zh_CN">
        <div id="app">
            <router-view />
        </div>
    </a-locale-provider>
</template>
<script>
import { ConfigProvider as ALocaleProvider } from 'ant-design-vue'
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import moment from 'moment'
import 'moment/locale/zh-cn'
moment.locale('zh-cn')

export default {
    name: 'App',
    components: {
        ALocaleProvider
    },
    data() {
        return {
            // eslint-disable-next-line camelcase
            zh_CN: zhCN
        }
    }
}
</script>

