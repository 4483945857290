/**
 * 企业管理
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    ENTERPRISE_MENU_CODE,
    ENTERPRISE_LIST_MENU_CODE,
    ENTERPRISE_INFO_MENU_CODE,
    ENTERPRISE_CREDIT_REPORT_MENU_CODE,
    FINANCIAL_REPORT_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/enterprise-management',
        name: 'Enterprise',
        redirect: '/enterprise-management/enterprise-list/index',
        component: BasicLayout,
        code: ENTERPRISE_MENU_CODE,
        sort: 3,
        meta: {
            title: '企业管理',
            icon: 'enterprise',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'enterprise-list',
                redirect: '/enterprise-management/enterprise-list/index',
                name: 'EnterpriseList',
                component: RouteLayout,
                code: ENTERPRISE_LIST_MENU_CODE,
                meta: {
                    title: '企业列表'
                },
                // hidden: true,
                children: [
                    {
                        path: 'index',
                        name: 'EnterpriseListIndex',
                        component: () => import('./EnterpriseList'),
                        code: ENTERPRISE_LIST_MENU_CODE,
                        meta: {
                            title: '企业列表',
                            breadcrumb: false
                        },
                        hidden: true
                    },
                    {
                        path: 'info',
                        name: 'EnterpriseListInfo',
                        component: () => import('./EnterpriseInfo'),
                        code: ENTERPRISE_INFO_MENU_CODE,
                        meta: {
                            title: '企业详情',
                            breadcrumb: true
                        },
                        hidden: true
                    },
                    {
                        path: 'credit-report-info',
                        name: 'CreditReportInfoIndex',
                        component: () => import('./CreditReportInfo'),
                        code: ENTERPRISE_CREDIT_REPORT_MENU_CODE,
                        meta: {
                            title: '征信报告',
                            breadcrumb: true
                        },
                        hidden: true
                    },
                    {
                        path: 'financial-report-info',
                        name: 'FinancialReportInfoIndex',
                        component: () => import('./FinancialReportInfo'),
                        code: FINANCIAL_REPORT_MENU_CODE,
                        meta: {
                            title: '财报信息',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            }
        ]
    }
]

export default routes
