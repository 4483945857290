import BasicLayout from '@layout/BasicLayout'

/**
 * 初始路由表
 * @type {*[]}
 */
export const constantRouterMap = [
    {
        path: '/',
        redirect: '/welcome',
        // redirect: '/home-page/index',
        hidden: true
    },
    {
        path: '/login',
        name: 'Login',
        hidden: true,
        component: () => import('@views/Login')
    },
    {
        path: '/404',
        component: () => import('@views/ErrorPage/404'),
        hidden: true
    },
    {
        path: '/500',
        component: () => import('@views/ErrorPage/500'),
        hidden: true
    },
    {
        path: '/welcome',
        component: BasicLayout,
        redirect: '/welcome/index',
        name: 'Welcome',
        hidden: true,
        children: [
            {
                path: 'index',
                component: () => import('@/views/Welcome'),
                name: 'WelcomePage'
            }
        ]
    }
]
/**
 * 异步加载路由表
 * @type {{path: string, name: string, component: (function(): (Promise<*>|*)), meta: {roles: *[]}}[]}
 */
// export const asyncRouterMap = [

//     {
//         path:'/test-record',
//         name:'TestRecord',
//         component:BasicLayout,
//         // meta: {
//         //     title: '测试记录',
//         //     icon: 'form'
//         // },
//         redirect:'/test-record/index',
//         children:[{
//             path: '/index',
//             name: 'TestRecordIndex',
//             component: () => import('@/views/TestRecord'),
//             meta: {
//                 title: '测试记录',
//                 icon: 'form'
//             }
//         },{
//             path: 'detail',
//             component: () => import('@/views/OrderDetail'),
//             name: 'TestRecordDetailPage',
//             hidden: true,
//             // meta: {
//             //     title: '测试记录',
//             //     icon: 'form'
//             // }
//         }]
//     }
// ]
