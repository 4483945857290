<template>
    <a-dropdown :trigger="['click']">
        <span class="header-notice" @click="fetchNotice">
            <a-badge :count="messageNum">
                <a-icon :class="['header-notice-icon', theme]" type="bell" />
            </a-badge>
        </span>
    </a-dropdown>
</template>

<script>

import {
    Dropdown as ADropdown,
    Badge as ABadge,
    Icon as AIcon
} from 'ant-design-vue'
export default {
    name: 'HeaderNotice',
    components: {
        ADropdown,
        ABadge,
        AIcon
    },
    data() {
        return {
            timer: null
        }
    },
    computed: {
        theme() {
            return this.$store.getters.layout === 'side' ? 'light' : this.$store.getters.theme
        },
        messageNum() {
            return this.$store.state.messageNum
        }
    },
    mounted() {
        this.getList()
        this.intervalGetMesNum()
    },
    destroyed() {
        this.timer && clearInterval(this.timer)
    },
    methods: {
        intervalGetMesNum() {
            this.timer = setInterval(() => {
                this.getList()
            }, 5 * 60 * 1000)
        },
        fetchNotice() {
            const name = this.$route.name
            if (name === 'MessageCenter') return
            this.$router.push({
                name: 'MessageCenter'
            })
        },
        getList() {
            this.$store.dispatch('getMessageNum')
        }
    }
}
</script>

<style lang="less">
.header-notice {
    display: inline-block;
    transition: all 0.3s;

    span {
        vertical-align: initial;
    }

    .header-notice-icon {
        font-size: 16px;
        padding: 4px;

        &.dark {
            color: #fff;
        }

        &.light {
            color: rgba(0, 0, 0, .65);
        }
    }
}

.ant-dropdown-menu-container {
    box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}
</style>
