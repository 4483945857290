// 系统名称
const SYSTEM_NAME = process.env.VUE_APP_SYSTEM_NAME
// 版权申明
const COPYRIGHT = process.env.VUE_APP_COPYRIGHT
// 备案编号
const LICENSE_NUMBER = process.env.VUE_APP_LICENSE_NUMBER

export default {
    // base
    // 用户信息
    userInfo: null,
    // 用户菜单权限
    role: null,
    // 菜单按钮权限
    buttonRole: null,
    // 菜单
    routes: [],
    // setting module
    isMobile: false,
    theme: 'light',
    layout: 'side',
    multipage: true,
    fixedHeader: true,
    fixedSidebar: true,
    reviewOperation: {},
    // 系统名称
    systemName: SYSTEM_NAME,
    // 版权申明
    copyright: COPYRIGHT,
    // 备案编号
    licenseNumber: LICENSE_NUMBER,
    messageNum: 0
}
