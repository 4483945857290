const files = require.context(
    './',
    false,
    /^\.\/(?!(?:index.js$)).+\.js$/
)

/**
 * @param'./auth.js'
 * @return 'auth'
 * */
function getDirecName(fileName) {
    return fileName.replace(/\.\/(.+)\.js$/, '$1')
}


export default {
    install: Vue => {
        files.keys().forEach(fileName => {
            const fileConfig = files(fileName)
            Vue.directive(getDirecName(fileName), fileConfig.default || fileConfig)
        })
    }
}
