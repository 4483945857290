/**
 * 系统首页
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    HOME_PAGE_MENU_CODE,
    SYS_HOMEPAGE_MENU_CODE,
    PERSONAL_SETTING_MENU_CODE,
    MESSAGE_CENTER_MENU_CODE,
    BASIC_SETTING_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/home-page',
        name: 'Home',
        redirect: '/home-page/index',
        component: BasicLayout,
        code: HOME_PAGE_MENU_CODE,
        sort: 1,
        meta: {
            title: '管理首页',
            icon: 'home',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'index',
                name: 'SysHomePage',
                component: () => import('./SysHomePage'),
                code: SYS_HOMEPAGE_MENU_CODE,
                meta: {
                    title: '系统首页',
                    breadcrumb: true
                }
            }, {
                path: 'personal-setting',
                name: 'PersonalSetting',
                component: RouteLayout,
                code: PERSONAL_SETTING_MENU_CODE,
                redirect: '/home-page/personal-setting/basic-setting',
                meta: {
                    title: '个人设置',
                    breadcrumb: true
                },
                children: [
                    {
                        path: 'basic-setting',
                        name: 'BasicSetting',
                        component: () => import('./PersonalSetting/BasicSetting'),
                        code: BASIC_SETTING_MENU_CODE,
                        meta: {
                            title: '基本设置',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                    // {
                //     path: 'safety-setting',
                //     name: 'SafetySetting',
                //     component: () => import('./PersonalSetting/SafetySetting'),
                //     code: SAFETY_SETTING_MENU_CODE,
                //     meta: {
                //         title: '安全设置',
                //         breadcrumb: true,
                //     },
                //     hidden: true
                // }
                ]
            }, {
                path: 'message-center',
                name: 'MessageCenter',
                component: () => import('./MessageCenter'),
                code: MESSAGE_CENTER_MENU_CODE,
                meta: {
                    title: '消息中心',
                    breadcrumb: true
                }
            }
        ]
    }
]

export default routes
