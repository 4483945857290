export const userInfo = state => state.userInfo

export const isMobile = state => state.isMobile

export const theme = state => state.theme

export const layout = state => state.layout


export const multipage = state => state.multipage

export const fixedHeader = state => state.fixedHeader

export const fixedSidebar = state => state.fixedSidebar

export const routes = state => state.routes

export const role = state => state.role

export const buttonRole = state => state.buttonRole

export const systemName = state => state.systemName

export const copyright = state => state.copyright

export const licenseNumber = state => state.licenseNumber


export const paymentEarnestMoneyManagementType = state => state.paymentEarnestMoneyManagementType

export const isInit = state => state.isInit

export const wagesMarginType = state => state.wagesMarginType

