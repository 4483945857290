<template>
    <layout-header :class="[theme, 'gongbao-header']">
        <div :class="['gongbao-header--wide', layout]">
            <router-link
                to="/"
                :class="['logo', isMobile ? null : 'pc', theme]">
                <img src="~@assets/images/logo.png">
                <h1 v-if="!isMobile">{{ systemName }}</h1>
            </router-link>
            <div v-if="layout === 'head'"
                 class="gongbao-header__menu">
                <i-menu class="gongbao-header__menu"
                        :theme="theme"
                        mode="horizontal"
                        :menu-data="menuData"
                        @select="onSelect" />
            </div>
            <div :class="['gongbao-header__right', theme]">
                <!--                <header-search class="header-item"/> -->
                <!-- <a-tooltip class="gongbao-header__item" title="帮助文档" placement="bottom">
                    <a>
                        <a-icon type="question-circle-o"/>
                    </a>
                </a-tooltip>
                -->
                <!-- 消息 -->
                <header-notice v-if="showNotice" class="gongbao-header__item" />
                <a-divider type="vertical" />

                <header-avatar class="gongbao-header__item" />
            </div>
        </div>
    </layout-header>
</template>

<script>
import {
    Layout,
    Divider as ADivider
} from 'ant-design-vue'
import HeaderNotice from '@components/HeaderNotice'
import HeaderAvatar from '@components/HeaderAvatar'
import IMenu from '@components/SiderMenu'
import { MESSAGE_CENTER_MENU_CODE } from '@constant/menu_code'


export default {
    name: 'GlobalHeader',
    components: {
        IMenu,
        HeaderAvatar,
        HeaderNotice,
        ADivider,
        LayoutHeader: Layout.Header
    },
    props: {
        menuData: {
            type: Array,
            default: () => []
        }
    },
    computed: {
        isMobile() {
            return this.$store.getters.isMobile
        },
        layout() {
            return this.$store.getters.layout
        },
        theme() {
            return this.layout === 'side' ? 'light' : this.$store.getters.theme
        },
        systemName() {
            return this.$store.getters.systemName
        },
        // 铃铛消息组件权限判断
        showNotice() {
            const role = this.$store.getters.role
            return Object.prototype.hasOwnProperty.call(role, MESSAGE_CENTER_MENU_CODE)
        }
    },
    methods: {
        onSelect(obj) {
            this.$emit('menu-select', obj)
        }
    }
}
</script>

<style lang="scss">
@import '@assets/styles/varibles.scss';

.gongbao-header {
    padding: 0 12px 0 0;
    /*-webkit-box-shadow: 0 1px 4px rgba(0, 21, 41, .08);*/
    box-shadow: 0 1px 4px rgba(0, 21, 41, .08);
    position: relative;

    &.light {
        background: #fff;
    }

    &.dark {
        background: #001529;
    }


    &__trigger {
        font-size: 20px;
        line-height: 64px;
        padding: 0 24px;
        cursor: pointer;
        transition: color .3s;

        &:hover {
            color: #1890ff;
        }
    }

    &--wide {
        &.head {
            max-width: 1400px;
            margin: auto;
        }

        .logo {
            height: 64px;
            line-height: 58px;
            vertical-align: top;
            display: inline-block;
            padding: 0 12px 0 38px;
            cursor: pointer;
            font-size: 20px;
            //&.pc {
            //  padding: 0 12px 0 0;
            //}

            img {
                display: inline-block;
                vertical-align: middle;
                height: 28px;
                margin-right: 38px;
            }

            h1 {
                margin: 0;
                display: inline-block;
                font-size: 18px;
            }

            &.dark h1 {
                color: #fff;
            }
        }

        .gongbao-header__menu {
            display: inline-block;
        }

        .gongbao-header__right {
            float: right;

            &.dark {
                color: #fff;

                i {
                    color: #fff;
                }
            }
        }
    }

    &__item {
        padding: 0 12px;
        display: inline-block;
        height: 100%;
        cursor: pointer;
        vertical-align: middle;

        i {
            font-size: 16px;
            color: rgba(0, 0, 0, .65);
        }
    }

    &__menu {
        height: $navbar-height;
        line-height: $navbar-height;
    }
}
</style>
