<template>
    <a-dropdown class="app-header-avatar">
        <span style="cursor: pointer">
            <a-avatar class="avatar" size="small" shape="circle" :src="avatar" />
            <span>{{ userInfo && userInfo.realName }}</span>
            <a-icon class="app-header-avatar__icon-down" type="down" />
        </span>
        <a-menu slot="overlay" style="width: 150px">
            <a-menu-item @click="handleLogOutClick">
                <a-icon type="poweroff" />
                <span>退出登录</span>
            </a-menu-item>
        </a-menu>
    </a-dropdown>
</template>

<script>
import {
    Avatar as AAvatar,
    Dropdown as ADropdown,
    Menu as AMenu,
    Icon as AIcon
} from 'ant-design-vue'
import logoutService from '@service/logoutService'

export default {
    name: 'HeaderAvatar',
    components: {
        AAvatar,
        ADropdown,
        AIcon,
        AMenu,
        AMenuItem: AMenu.Item
    },
    computed: {
        userInfo() {
            return this.$store.getters.userInfo || {}
        },
        avatar() {
            const { userInfo } = this
            return userInfo && userInfo.avatar
        }
    },
    methods: {
        async handleLogOutClick() {
            try {
                await logoutService()
            } catch (e) {
                this.$message.error(e.message)
                this.$captureException(e.message)
            }
            this.$store.dispatch('logOut')
        }
    }
}
</script>

<style lang="scss">
@import '~@assets/styles/varibles.scss';

.app-header-avatar {
  display: inline-block;
  height: 100%;
  vertical-align: initial;

  .avatar {
    margin: 20px 4px 20px 0;
    color: #1890ff;
    background: hsla(0, 0%, 100%, .85);
    vertical-align: middle;
  }

  &__icon-down {
    margin-left: $space-row-base;
  }
}


</style>
