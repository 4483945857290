import { setToken, removeToken } from '@utils/auth'
import {
    SET_TOKEN,
    SET_USER_INFO,
    SET_ROUTES,
    SET_ROLE,
    SET_BUTTON_ROLE,
    SET_MESSAGE_NUM

} from './mutation-types'

import asyncRouterMap from '@router/asyncLoadRoutes'


import getUserInfoService from '@service/getUserInfoService'
import getMenusService from '@service/getMenusService'
import loginByUserNameService from '@service/loginByUserNameService'
import getMessageListService from '@service/home/getMessageListService'

// import tree from '@constant/tree'

/**
 * 通过roles判断是否与当前用户权限匹配
 * @param route
 * @param roles
 */
function hasPermission(route, roles) {
    return roles[route.code]
}

/**
 * 格式化 权限为对象
 * @param permissions
 * @returns {{}}
 */

/* function getRule(permissions) {
    permissions = permissions || []
    let menuObj = {}
    let buttonObj = {}
    permissions.forEach((item) => {
        const {component, children} = item
        if (children && children.length) {
            Object.assign(menuObj, getRule(children).menu)
        }
        menuObj[component] = item
    })

    return {
        menu: menuObj,
        button: buttonObj,
    }
}*/
function getRule(permissions) {
    permissions = permissions || []
    let menuObj = {}
    permissions.forEach(item => {
        const { component, children } = item
        if (children && children.length) {
            Object.assign(menuObj, getRule(children))
        }
        menuObj[component] = item
    })
    return menuObj
}

/**
 * 递归过滤异步路由表，返回符合用户角色权限的路由表
 * @param routes asyncRouterMap
 * @param roles
 */
function filterAsyncRouter(routes, roles = {}) {
    const res = []
    routes.forEach(route => {
        const tmp = {
            ...route
        }

        if (hasPermission(tmp, roles)) {
            // 子路由权限
            if (tmp.children) {
                tmp.children = filterAsyncRouter(tmp.children, roles)
            }
            // 判断 是否不拒绝重写重定向 并且
            // 有配置 redirect 属性 并且
            // 有子路由
            if (tmp.doNotRewriteRedirect !== false &&
                tmp.redirect &&
                tmp.children.length) {
                const childItem = tmp.children[0]
                tmp.redirect = {
                    name: childItem.name
                }
            }

            res.push(tmp)
        }
    })

    return res
}

/**
 * 登录
 * @param commit
 * @param loginInfo
 * @returns {Promise<void>}
 */
export async function loginByUserName({ commit }, loginInfo) {
    // const token = 2
    // commit(SET_TOKEN, token)
    // setToken(token)
    // return

    const res = await loginByUserNameService(loginInfo)
    commit(SET_TOKEN, res)
    setToken(res)
}

/**
 * 获取用户信息
 * @param commit
 * @param state
 * @returns {Promise<void>}
 */
export async function getUserInfo({ commit }) {

    // const userInfo = {
    //     compCode: "",
    //     compName: "",
    //     name: "系统管理员",
    //     avatar:'https://gw.alipayobjects.com/zos/antfincdn/XAosXuNZyF/BiazfanxmamNRoxxVxka.png'
    // }
    // const menu = getRule(tree)
    // commit(SET_ROLE, menu)
    //
    // // 用户信息
    // commit(SET_USER_INFO, userInfo)
    // return

    const userInfo = await getUserInfoService()
    if (!userInfo) throw new Error('未获取到用户信息')
    const menuTreeSet = await getMenusService()
    const menu = getRule(menuTreeSet)
    commit(SET_ROLE, menu)
    commit(SET_BUTTON_ROLE, {})
    // 用户信息
    commit(SET_USER_INFO, userInfo)
}

/**
 * 登出系统
 * @param commit
 * @param state
 * @returns {Promise<void>}
 */
export async function logOut({ commit }) {
    commit(SET_ROLE, [])
    commit(SET_USER_INFO, null)
    commit(SET_TOKEN, '')
    removeToken()
    window.location.reload()
}

/**
 * 生成路由表
 * @param commit
 * @param state
 * @param roles
 */
export function generateRoutes({ commit, state }) {
    const role = state.role || {}
    let accessedRouters = filterAsyncRouter(asyncRouterMap, role)
    commit(SET_ROUTES, accessedRouters)
    return accessedRouters
    // commit(SET_ROUTES,asyncRouterMap)
    // return asyncRouterMap
}

/**
 * 获取未读消息数
 * */
export async function getMessageNum({ commit }) {
    try {
        const { total } = await getMessageListService({
            current: 1,
            size: 10,
            state: 0
        })
        commit(SET_MESSAGE_NUM, total)
    } catch (e) {
        this._vm.$message.error(e.message)
        this._vm.$captureException(e)
    }
}
