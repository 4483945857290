/**
 * 任务管理
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    TASK_MENU_CODE,
    TASK_LIST_MENU_CODE,
    TASK_INFO_MENU_CODE,
    TASK_INFO_RISK_CONTROL_INFORMATION_MENU_CODE,
    TASK_INFO_ADD_FINANCIAL_REPORT_MENU_CODE,
    TASK_INFO_RISK_ASSESS_MENU_CODE,
    TASK_INFO_EDIT_RISK_GRADE_MENU_CODE,

    TASK_DESIGNATE_LIST_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/task-management',
        name: 'Task',
        redirect: '/task-management/task-list',
        component: BasicLayout,
        code: TASK_MENU_CODE,
        sort: 2,
        meta: {
            title: '任务管理',
            icon: 'task',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'task-list',
                redirect: '/task-management/task-list/list',
                name: 'TaskList',
                component: RouteLayout,
                code: TASK_LIST_MENU_CODE,
                meta: {
                    title: '任务列表'
                },
                // hidden: true,
                children: [
                    {
                        path: 'list',
                        name: 'TaskListIndex',
                        component: () => import('./TaskList'),
                        code: TASK_LIST_MENU_CODE,
                        meta: {
                            title: '任务列表',
                            breadcrumb: false
                        },
                        hidden: true
                    },
                    {
                        path: 'info',
                        name: 'TaskInfo',
                        component: () => import('./TaskInfo'),
                        code: TASK_INFO_MENU_CODE,
                        meta: {
                            title: '任务详情',
                            breadcrumb: true
                        },
                        hidden: true,
                        children: []
                    },
                    {
                        path: 'risk-control-information',
                        name: 'RiskControlInformation',
                        component: () => import('./RiskControlInformation'),
                        code: TASK_INFO_RISK_CONTROL_INFORMATION_MENU_CODE,
                        meta: {
                            title: '数据核验',
                            breadcrumb: true
                        },
                        hidden: true
                    },
                    {
                        path: 'add-financial-report',
                        name: 'AddFinancialReport',
                        component: () => import('./AddFinancialReport'),
                        code: TASK_INFO_ADD_FINANCIAL_REPORT_MENU_CODE,
                        meta: {
                            title: '财报信息',
                            breadcrumb: true
                        },
                        hidden: true
                    },
                    {
                        path: 'risk-assess',
                        name: 'RiskAssess',
                        component: () => import('./RiskAssess'),
                        code: TASK_INFO_RISK_ASSESS_MENU_CODE,
                        meta: {
                            title: '风险评估',
                            breadcrumb: true
                        },
                        hidden: true
                    },
                    {
                        path: 'edit-risk-grade',
                        name: 'EditRiskGrade',
                        component: () => import('./EditRiskGrade'),
                        code: TASK_INFO_EDIT_RISK_GRADE_MENU_CODE,
                        meta: {
                            title: '修改风险评分',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            }, {
                path: 'task-designate',
                redirect: '/task-management/task-designate/list',
                name: 'TaskDesignateList',
                component: RouteLayout,
                code: TASK_DESIGNATE_LIST_MENU_CODE,
                meta: {
                    title: '任务指派'
                },
                children: [
                    {
                        path: 'list',
                        name: 'TaskDesignate',
                        component: () => import('./TaskDesignate'),
                        code: TASK_DESIGNATE_LIST_MENU_CODE,
                        meta: {
                            title: '任务指派',
                            breadcrumb: false
                        },
                        hidden: true
                    }
                ]
            }
        ]
    }
]

export default routes
