import './assets/fonts/iconfont.css'
import 'nprogress/nprogress.css'
import './assets/styles/index.scss'

import 'moment/locale/zh-cn'
import NProgress from 'nprogress' // progress bar
import Vue from 'vue'

import App from './App.vue'
import store from './store'
import router from './router'

import Directive from './directive/index'
import './router/permission'

import { captureException, captureMessage, setUser } from '@utils/log'

import { message, Modal, notification } from 'ant-design-vue'


const isDebugMode = process.env.NODE_ENV !== 'production'
Vue.config.debug = isDebugMode
Vue.config.devtools = isDebugMode
Vue.config.productionTip = isDebugMode

Vue.prototype.$captureException = captureException
Vue.prototype.$captureMessage = captureMessage
Vue.prototype.$setSentryUser = setUser

// 注册 鉴权指令
Vue.use(Directive)
Vue.use(Modal)


Vue.prototype.$message = message
Vue.prototype.$notification = notification
Vue.prototype.$info = Modal.info
Vue.prototype.$success = Modal.success
Vue.prototype.$error = Modal.error
Vue.prototype.$warning = Modal.warning
Vue.prototype.$confirm = Modal.confirm
Vue.prototype.$destroyAll = Modal.destroyAll
// // NProgress Configuration
NProgress.configure({
    showSpinner: false
})

window.NProgress = NProgress

new Vue({
    router,
    store,
    render: h => h(App)
}).$mount('#app')
