/**
 * 菜单code
 * @type {string}
 */

/**
 * 规则引擎code
 * ——————————————————————————————————————————————————————————————————————————————————————————————
 */
// 规则引擎
export const RULE_ENGINE_MENU_CODE = 'RULE_ENGINE_MENU_CODE'
// 数据核验模型
export const RULE_ENGINE_DATA_CHECK_MENU_CODE = 'RULE_ENGINE_DATA_CHECK_MENU_CODE'
// 数据核验模型详情
export const RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE = 'RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE'
// 数据核验模型详情-添加风险因子
export const RULE_ENGINE_DATA_CHECK_INFO_ADD_RISK_FACTOR_MENU_CODE = 'RULE_ENGINE_DATA_CHECK_INFO_ADD_RISK_FACTOR_MENU_CODE'

// 规则引擎-风险因子
export const RULE_ENGINE_RISK_FACTOR_CONFIG_MENU_CODE = 'RULE_ENGINE_RISK_FACTOR_CONFIG_MENU_CODE'

// 规则引擎-风控模型
export const RULE_ENGINE_RISK_CONTROL_CONFIG_MENU_CODE = 'RULE_ENGINE_RISK_CONTROL_CONFIG_MENU_CODE'

// 规则引擎-风险评估模型
export const RULE_ENGINE_RISK_ASSESSMENT_CONFIG_MENU_CODE = 'RULE_ENGINE_RISK_ASSESSMENT_CONFIG_MENU_CODE'
// 风险评估模型详情
export const RULE_ENGINE_RISK_ASSESSMENT_INFO_MENU_CODE = 'RULE_ENGINE_RISK_ASSESSMENT_INFO_MENU_CODE'
// 风险评估模型详情-添加风险评估模型内容
export const ADD_RISK_ASSESSMENT_CONTENT_MENU_CODE = 'ADD_RISK_ASSESSMENT_CONTENT_MENU_CODE'

// 规则引擎-评分模型
export const RULE_ENGINE_GRADE_MODEL_CONFIG_MENU_CODE = 'RULE_ENGINE_GRADE_MODEL_CONFIG_MENU_CODE'
// 规则引擎-评分模型列表
export const RULE_ENGINE_GRADE_MODEL_LIST_MENU_CODE = 'RULE_ENGINE_GRADE_MODEL_LIST_MENU_CODE'
// 规则引擎-评分模型列表
export const RULE_ENGINE_GRADE_MODEL_INFO_MENU_CODE = 'RULE_ENGINE_GRADE_MODEL_INFO_MENU_CODE'
// 规则引擎-评分模型详情-评分因子-添加/编辑评分因子
export const RULE_ENGINE_GRADE_MODEL_INFO_ADD_GRADE_FACTOR_MENU_CODE = 'RULE_ENGINE_GRADE_MODEL_INFO_ADD_GRADE_FACTOR_MENU_CODE'

// 规则引擎-风险等级模型
export const RULE_ENGINE_RISK_CLASS_CONFIG_MENU_CODE = 'RULE_ENGINE_RISK_CLASS_CONFIG_MENU_CODE'
// 规则引擎-风险等级模型-列表
export const RULE_ENGINE_RISK_CLASS_LIST_MENU_CODE = 'RULE_ENGINE_RISK_CLASS_LIST_MENU_CODE'
// 规则引擎-风险等级模型-详情页
export const RULE_ENGINE_RISK_CLASS_INFO_MENU_CODE = 'RULE_ENGINE_RISK_CLASS_INFO_MENU_CODE'

// 规则引擎-智能评估模型
export const RULE_ENGINE_SMART_ASSESS_CONFIG_MENU_CODE = 'RULE_ENGINE_SMART_ASSESS_CONFIG_MENU_CODE'
// 规则引擎-风险等级模型-列表
export const RULE_ENGINE_SMART_ASSESS_LIST_MENU_CODE = 'RULE_ENGINE_SMART_ASSESS_LIST_MENU_CODE'
// 规则引擎-风险等级模型-详情页
export const RULE_ENGINE_SMART_ASSESS_INFO_MENU_CODE = 'RULE_ENGINE_SMART_ASSESS_INFO_MENU_CODE'

// 规则引擎-基础规则-列表
export const RULE_ENGINE_BASE_RULE_LIST_MENU_CODE = 'RULE_ENGINE_BASE_RULE_LIST_MENU_CODE'
// 规则引擎-基础规则-添加或者修改基础规则
export const RULE_ENGINE_ADD_OR_EDIT_BASE_RULE_MENU_CODE = 'RULE_ENGINE_ADD_OR_EDIT_BASE_RULE_MENU_CODE'

// 规则引擎-规则集-列表
export const RULE_ENGINE_RULE_SET_LIST_MENU_CODE = 'RULE_ENGINE_RULE_SET_LIST_MENU_CODE'
// 规则引擎-规则集-详情页
export const RULE_ENGINE_RULE_SET_INFO_MENU_CODE = 'RULE_ENGINE_RULE_SET_INFO_MENU_CODE'
// 规则引擎-规则集-增加规则集
export const RULE_ENGINE_ADD_RULE_SET_MENU_CODE = 'RULE_ENGINE_ADD_RULE_SET_MENU_CODE'


/**
 * 任务管理
 * */
export const TASK_MENU_CODE = 'TASK_MENU_CODE'
// 任务列表
export const TASK_LIST_MENU_CODE = 'TASK_LIST_MENU_CODE'
// 任务详情
export const TASK_INFO_MENU_CODE = 'TASK_INFO_MENU_CODE'
// 任务详情-风控信息
export const TASK_INFO_RISK_CONTROL_INFORMATION_MENU_CODE = 'TASK_INFO_RISK_CONTROL_INFORMATION_MENU_CODE'
// 任务详情-财报信息
export const TASK_INFO_ADD_FINANCIAL_REPORT_MENU_CODE = 'TASK_INFO_ADD_FINANCIAL_REPORT_MENU_CODE'
// 任务详情-评估信息
export const TASK_INFO_RISK_ASSESS_MENU_CODE = 'TASK_INFO_RISK_ASSESS_MENU_CODE'
// 任务详情-修改风险评分
export const TASK_INFO_EDIT_RISK_GRADE_MENU_CODE = 'TASK_INFO_EDIT_RISK_GRADE_MENU_CODE'

// 任务指派
export const TASK_DESIGNATE_LIST_MENU_CODE = 'TASK_DESIGNATE_LIST_MENU_CODE'


/**
 * 商品管理
 * */

// 商品管理
export const PRODUCT_MANAGE_MENU_CODE = 'PRODUCT_MANAGE_MENU_CODE'
// 商品管理-产品分类
export const PRODUCT_MANAGE_PRODUCT_CATEGORY_MENU_CODE = 'PRODUCT_MANAGE_PRODUCT_CATEGORY_MENU_CODE'
// 商品管理-参数列表
export const PRODUCT_MANAGE_PRODUCT_PARAMS_LIST_MENU_CODE = 'PRODUCT_MANAGE_PRODUCT_PARAMS_LIST_MENU_CODE'
// 商品管理-商品列表
export const PRODUCT_MANAGE_PRODUCT_COMMODITY_LIST_MENU_CODE = 'PRODUCT_MANAGE_PRODUCT_COMMODITY_LIST_MENU_CODE'
// 商品管理-商品详情
export const PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE = 'PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE'
// 商品管理-添加商品
export const PRODUCT_MANAGE_PRODUCT_ADD_COMMODITY_MENU_CODE = 'PRODUCT_MANAGE_PRODUCT_ADD_COMMODITY_MENU_CODE'


/**
 * 订单管理
 * */

// 订单管理
export const ORDER_MENU_CODE = 'ORDER_MENU_CODE'
// 订单管理-订单列表
export const ORDER_LIST_MENU_CODE = 'ORDER_LIST_MENU_CODE'
// 订单管理-订单详情
export const ORDER_INFO_MENU_CODE = 'ORDER_INFO_MENU_CODE'


/**
 * 企业管理
 * */
// 企业管理
export const ENTERPRISE_MENU_CODE = 'ENTERPRISE_MENU_CODE'
// 企业管理-企业列表
export const ENTERPRISE_LIST_MENU_CODE = 'ENTERPRISE_LIST_MENU_CODE'
// 企业管理-企业详情
export const ENTERPRISE_INFO_MENU_CODE = 'ENTERPRISE_INFO_MENU_CODE'
// 企业管理-征信报告
export const ENTERPRISE_CREDIT_REPORT_MENU_CODE = 'ENTERPRISE_CREDIT_REPORT_MENU_CODE'
// 企业管理-财报信息
export const FINANCIAL_REPORT_MENU_CODE = 'FINANCIAL_REPORT_MENU_CODE'


/**
 * 管理首页
 * */
// 管理首页
export const HOME_PAGE_MENU_CODE = 'HOME_PAGE_MENU_CODE'
// 系统首页
export const SYS_HOMEPAGE_MENU_CODE = 'SYS_HOMEPAGE_MENU_CODE'
// 个人设置
export const PERSONAL_SETTING_MENU_CODE = 'PERSONAL_SETTING_MENU_CODE'
// 基本设置
export const BASIC_SETTING_MENU_CODE = 'BASIC_SETTING_MENU_CODE'
// 安全设置
export const SAFETY_SETTING_MENU_CODE = 'SAFETY_SETTING_MENU_CODE'
// 消息通知
export const MESSAGE_CENTER_MENU_CODE = 'MESSAGE_CENTER_MENU_CODE'


/**
 * 审批及日志
 * */
// 审批及日志
export const APPROVE_MANAGEMENT_MENU_CODE = 'APPROVE_MANAGEMENT_MENU_CODE'
// 规则审核列表
export const RULE_APPROVE_LIST_MENU_CODE = 'RULE_APPROVE_LIST_MENU_CODE'
// 规则审核详情
export const RULE_APPROVE_INFO_MENU_CODE = 'RULE_APPROVE_INFO_MENU_CODE'
// 商品审核列表
export const COMMODITY_APPROVE_LIST_MENU_CODE = 'COMMODITY_APPROVE_LIST_MENU_CODE'
// 商品审核详情
export const COMMODITY_APPROVE_INFO_MENU_CODE = 'COMMODITY_APPROVE_INFO_MENU_CODE'
// 操作日志列表
export const OPERATION_LOG_LIST_MENU_CODE = 'OPERATION_LOG_LIST_MENU_CODE'
// 查看评估内容
export const RULE_LOOK_RISK_ASSESSMENT_CONTENT_MENU_CODE = 'RULE_LOOK_RISK_ASSESSMENT_CONTENT_MENU_CODE'
// 查看数据核验内容
export const RULE_LOOK_DATA_CHECK_INFO_MENU_CODE = 'RULE_LOOK_DATA_CHECK_INFO_MENU_CODE'
// 查看评分模型内容
export const RULE_LOOK_GRADE_MODEL_INFO_MENU_CODE = 'RULE_LOOK_GRADE_MODEL_INFO_MENU_CODE'
// 查看风险评估模型详情内容
export const RULE_LOOK_RISK_ASSESSMENT_INFO_MENU_CODE = 'RULE_LOOK_RISK_ASSESSMENT_INFO_MENU_CODE'
// 查看风险等级模型详情内容
export const RULE_LOOK_RISK_CLASS_INFO_MENU_CODE = 'RULE_LOOK_RISK_CLASS_INFO_MENU_CODE'
// 查看智能评估模型详情内容
export const RULE_LOOK_SMART_ASSESS_INFO_MENU_CODE = 'RULE_LOOK_SMART_ASSESS_INFO_MENU_CODE'
// 查看规则集详情内容
export const RULE_LOOK_RULE_SET_INFO_MENU_CODE = 'RULE_LOOK_RULE_SET_INFO_MENU_CODE'

/**
 * 报告
 * */
export const RISK_CONTROL_BEFORE_REPORT_MENU_CODE = 'RISK_CONTROL_BEFORE_REPORT_MENU_CODE'
export const RISK_CONTROL_BEFORE_REPORT_LIST_MENU_CODE = 'RISK_CONTROL_BEFORE_REPORT_LIST_MENU_CODE'
export const RISK_CONTROL_BEFORE_REPORT_INFO_MENU_CODE = 'RISK_CONTROL_BEFORE_REPORT_INFO_MENU_CODE'

/**
 * 风控采购
 */
export const RISK_CONTROL_PROCUREMENT_MENU_CODE = 'RISK_CONTROL_PROCUREMENT_MENU_CODE'
// 采购列表
export const RISK_CONTROL_PROCUREMENT_PROCUREMENT_LIST_MENU_CODE = 'RISK_CONTROL_PROCUREMENT_PROCUREMENT_LIST_MENU_CODE'
// 订单列表
export const RISK_CONTROL_PROCUREMENT_ORDER_LIST_MENU_CODE = 'RISK_CONTROL_PROCUREMENT_ORDER_LIST_MENU_CODE'
// 订单详情
export const RISK_CONTROL_PROCUREMENT_ORDER_INFO_MENU_CODE = 'RISK_CONTROL_PROCUREMENT_ORDER_INFO_MENU_CODE'
// 导入承保结果
export const RISK_CONTROL_PROCUREMENT_IMPORT_UNDER_MENU_CODE = 'RISK_CONTROL_PROCUREMENT_IMPORT_UNDER_MENU_CODE'
// 提交订单
export const RISK_CONTROL_PROCUREMENT_SUBMIT_ORDER_MENU_CODE = 'RISK_CONTROL_PROCUREMENT_SUBMIT_ORDER_MENU_CODE'

