// 接口地址
export const BASE_URL = process.env.VUE_APP_BASE_URL

// 接口版本号
export const API_VERSION = '1.0.0'

// 媒体查询枚举
export const MediaQueryEnum = {
    'screen-xs': {
        maxWidth: 575
    },
    'screen-sm': {
        minWidth: 576,
        maxWidth: 767
    },
    'screen-md': {
        minWidth: 768,
        maxWidth: 991
    },
    'screen-lg': {
        minWidth: 992,
        maxWidth: 1199
    },
    'screen-xl': {
        minWidth: 1200,
        maxWidth: 1599
    },
    'screen-xxl': {
        minWidth: 1600
    }
}

// 系统免鉴权白名单
export const WHITE_LIST = [
    '/login',
    '/registration',
    '/404',
    '/401',
    '/500'
]

// 默认跳转路径
export const DEFAULT_REDIRECT_PATH = '/'

// 系统token key 名称
export const TOKEN_KEY = 'Admin-Token'

// token 有效期 （天）
export const TOKEN_TIME = 1

// 默认table 分页数量
export const DEFAULT_PAGES_SIZE = 10
// 详情记录列表 分页数量
export const DETAIL_LIST_PAGE_SIZE = 5


// 客户端id
export const CLIENT_ID = 'cms'
// 客户端秘钥
export const CLIENT_SECRET = 'cms_secret'

