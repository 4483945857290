import Vue from 'vue'
import { init, captureException as outCaptureException, captureMessage as outCaptureMessage, setUser as outSetUser } from '@sentry/vue'
import { Integrations } from '@sentry/tracing'


// sentry dsn
const dsn = process.env.VUE_APP_SENTRY_DSN || ''
// 是否开启sentry
const OPEN_SENTRY = process.env.VUE_APP_SENTRY_SWITCH === 'true'
// 是否输出异常
const LOG_ERROR = process.env.VUE_APP_SENTRY_LOG_ERROR === 'true'
// 运行环境
const ENV = process.env.NODE_ENV
const LEVEL = {
    fatal: 'fatal',
    error: 'error',
    warning: 'warning',
    log: 'log',
    info: 'info',
    debug: 'debug',
    critical: 'critical'
}

// 是否开启 sentry
if (OPEN_SENTRY) {
    init({
        Vue,
        environment: ENV,
        dsn,
        logErrors: LOG_ERROR,
        integrations: [new Integrations.BrowserTracing()],
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 1.0
    })
}

/**
 * 异常捕获
 * @param error
 */
export function captureException(error) {
    if (OPEN_SENTRY) {
        outCaptureException(error)
    }
}

/**
 * 消息输出
 * @param message
 * @param level
 */
export function captureMessage(message, level) {
    if (OPEN_SENTRY) {
        const lev = LEVEL[level] || LEVEL.info
        outCaptureMessage(message, lev)
    }
}

/**
 * 设置用户信息
 * @param user
 */
export function setUser(user) {
    if (OPEN_SENTRY) {
        outSetUser(user)
    }
}
