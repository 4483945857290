/**
 * 规则引擎
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    RULE_ENGINE_MENU_CODE,
    RULE_ENGINE_DATA_CHECK_MENU_CODE,
    RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE,
    RULE_ENGINE_DATA_CHECK_INFO_ADD_RISK_FACTOR_MENU_CODE,
    RULE_ENGINE_RISK_FACTOR_CONFIG_MENU_CODE,
    RULE_ENGINE_RISK_CONTROL_CONFIG_MENU_CODE,
    RULE_ENGINE_RISK_ASSESSMENT_CONFIG_MENU_CODE,
    RULE_ENGINE_RISK_ASSESSMENT_INFO_MENU_CODE,
    ADD_RISK_ASSESSMENT_CONTENT_MENU_CODE,
    RULE_ENGINE_GRADE_MODEL_CONFIG_MENU_CODE,
    RULE_ENGINE_GRADE_MODEL_LIST_MENU_CODE,
    RULE_ENGINE_GRADE_MODEL_INFO_MENU_CODE,
    RULE_ENGINE_GRADE_MODEL_INFO_ADD_GRADE_FACTOR_MENU_CODE,
    RULE_ENGINE_RISK_CLASS_CONFIG_MENU_CODE,
    RULE_ENGINE_RISK_CLASS_LIST_MENU_CODE,
    RULE_ENGINE_RISK_CLASS_INFO_MENU_CODE,
    RULE_ENGINE_SMART_ASSESS_CONFIG_MENU_CODE,
    RULE_ENGINE_SMART_ASSESS_LIST_MENU_CODE,
    RULE_ENGINE_SMART_ASSESS_INFO_MENU_CODE,
    RULE_ENGINE_BASE_RULE_LIST_MENU_CODE,
    RULE_ENGINE_ADD_OR_EDIT_BASE_RULE_MENU_CODE,
    RULE_ENGINE_RULE_SET_LIST_MENU_CODE,
    RULE_ENGINE_RULE_SET_INFO_MENU_CODE,
    RULE_ENGINE_ADD_RULE_SET_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/rule-engine',
        name: 'RuleEngine',
        redirect: '/rule-engine/data-check-config/data-check-list',
        component: BasicLayout,
        code: RULE_ENGINE_MENU_CODE,
        sort: 5,
        meta: {
            title: '规则引擎',
            icon: 'engine',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'risk-factor-list',
                name: 'RiskFactorList',
                component: () => import('./RiskFactor'),
                code: RULE_ENGINE_RISK_FACTOR_CONFIG_MENU_CODE,
                meta: {
                    title: '风险因子'

                }
            },
            {
                redirect: '/rule-engine/base-rule-config/base-rule-list',
                component: RouteLayout,
                path: 'base-rule-config',
                name: 'BaseRuleConfig',
                code: RULE_ENGINE_BASE_RULE_LIST_MENU_CODE,
                meta: {
                    title: '基础规则'
                },
                children: [
                    {
                        path: 'base-rule-list',
                        name: 'BaseRuleList',
                        component: () => import('./BaseRule/BaseRuleList'),
                        code: RULE_ENGINE_BASE_RULE_LIST_MENU_CODE,
                        meta: {
                            title: '基础规则',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'add-or-edit-base-rule',
                        name: 'AddOrEditBaseRule',
                        component: () => import('./BaseRule/AddOrEditBaseRule'),
                        code: RULE_ENGINE_ADD_OR_EDIT_BASE_RULE_MENU_CODE,
                        meta: {
                            title: '增加基础规则'

                        },
                        hidden: true
                    }
                ]
            },
            {
                redirect: '/rule-engine/rule-set-config/rule-set-list',
                component: RouteLayout,
                path: 'rule-set-config',
                name: 'RuleSetConfig',
                code: RULE_ENGINE_RULE_SET_LIST_MENU_CODE,
                meta: {
                    title: '规则集'
                },
                children: [
                    {
                        path: 'rule-set-list',
                        name: 'RuleSetList',
                        component: () => import('./RuleSet/RuleSetList'),
                        code: RULE_ENGINE_RULE_SET_LIST_MENU_CODE,
                        meta: {
                            title: '规则集',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'rule-set-info',
                        name: 'RuleSetInfo',
                        component: () => import('./RuleSet/RuleSetInfo'),
                        code: RULE_ENGINE_RULE_SET_INFO_MENU_CODE,
                        meta: {
                            title: '规则集详情'

                        },
                        hidden: true
                    }, {
                        path: 'add-rule-set',
                        name: 'AddRuleSet',
                        component: () => import('./RuleSet/AddRuleSet'),
                        code: RULE_ENGINE_ADD_RULE_SET_MENU_CODE,
                        meta: {
                            title: '增加规则集'

                        },
                        hidden: true
                    }
                ]
            },
            {
                path: 'data-check-config',
                redirect: '/rule-engine/data-check-config/data-check-list',
                name: 'DataCheckConfig',
                component: RouteLayout,
                code: RULE_ENGINE_DATA_CHECK_MENU_CODE,
                meta: {
                    title: '数据核验模型'

                },
                children: [
                    {
                        path: 'data-check-list',
                        name: 'DataCheckList',
                        component: () => import('./DataCheckConfig/DataCheckList'),
                        code: RULE_ENGINE_DATA_CHECK_MENU_CODE,
                        meta: {
                            title: '数据核验模型',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'data-check-info',
                        name: 'DataCheckInfo',
                        component: () => import('./DataCheckConfig/DataCheckInfo'),
                        code: RULE_ENGINE_DATA_CHECK_INFO_MENU_CODE,
                        meta: {
                            title: '数据核验模型详情'

                        },
                        hidden: true
                    }, {
                        path: 'add-risk-factor',
                        name: 'AddRiskFactor',
                        component: () => import('./DataCheckConfig/AddRiskFactor'),
                        code: RULE_ENGINE_DATA_CHECK_INFO_ADD_RISK_FACTOR_MENU_CODE,
                        meta: {
                            title: '添加风险因子'

                        },
                        hidden: true
                    }

                ]
            },
            {
                path: 'risk-control-list',
                name: 'RiskControlList',
                component: () => import('./RiskControlModel'),
                code: RULE_ENGINE_RISK_CONTROL_CONFIG_MENU_CODE,
                meta: {
                    title: '风控模型'

                }
            },
            {
                path: 'risk-assessment-config',
                redirect: '/rule-engine/risk-assessment-config/list',
                name: 'RiskAssessmentConfig',
                component: RouteLayout,
                code: RULE_ENGINE_RISK_ASSESSMENT_CONFIG_MENU_CODE,
                meta: {
                    title: '风险评估模型'

                },
                children: [
                    {
                        path: 'list',
                        name: 'RiskAssessmentModel',
                        component: () => import('./RiskAssessmentModel/RiskAssessmentList'),
                        code: RULE_ENGINE_RISK_ASSESSMENT_CONFIG_MENU_CODE,
                        meta: {
                            title: '风险评估模型',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'risk-assessment-info',
                        name: 'RiskAssessmentInfo',
                        component: () => import('./RiskAssessmentModel/RiskAssessmentInfo'),
                        code: RULE_ENGINE_RISK_ASSESSMENT_INFO_MENU_CODE,
                        meta: {
                            title: '风险评估模型详情'

                        },
                        hidden: true
                    }, {
                        path: 'add-risk-assessment-content',
                        name: 'AddOrEditRiskAssessmentContent',
                        component: () => import('./RiskAssessmentModel/AddOrEditRiskAssessmentContent'),
                        code: ADD_RISK_ASSESSMENT_CONTENT_MENU_CODE,
                        meta: {
                            title: '添加风险评估内容'

                        },
                        hidden: true
                    }
                ]
            },
            {
                path: 'grade-model-config',
                redirect: '/rule-engine/grade-model-config/list',
                name: 'GradeModelConfig',
                component: RouteLayout,
                code: RULE_ENGINE_GRADE_MODEL_CONFIG_MENU_CODE,
                meta: {
                    title: '评分模型'

                },
                children: [
                    {
                        path: 'list',
                        name: 'GradeModelList',
                        component: () => import('./GradeModel/GradeModelList'),
                        code: RULE_ENGINE_GRADE_MODEL_LIST_MENU_CODE,
                        meta: {
                            title: '评分模型',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'info',
                        name: 'GradeModelInfo',
                        component: () => import('./GradeModel/GradeModelInfo'),
                        code: RULE_ENGINE_GRADE_MODEL_INFO_MENU_CODE,
                        meta: {
                            title: '模型详情',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'add-edit-grade-factor',
                        name: 'AddOrEditGradeFactor',
                        component: () => import('./GradeModel/GradeModelInfo/AddOrEditGradeFactor'),
                        code: RULE_ENGINE_GRADE_MODEL_INFO_ADD_GRADE_FACTOR_MENU_CODE,
                        meta: {
                            title: '评分因子',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            },
            {

                path: 'risk-class-config',
                name: 'RiskClassConfig',
                meta: {
                    title: '风险等级模型'
                },
                redirect: '/rule-engine/risk-class-config/list',
                component: RouteLayout,
                code: RULE_ENGINE_RISK_CLASS_CONFIG_MENU_CODE,
                children: [
                    {
                        path: 'list',
                        name: 'RiskClassList',
                        component: () => import('./RiskClass'),
                        code: RULE_ENGINE_RISK_CLASS_LIST_MENU_CODE,
                        meta: {
                            title: '风险等级模型',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'info',
                        name: 'RiskClassInfo',
                        component: () => import('./RiskClass/RiskClassInfo'),
                        code: RULE_ENGINE_RISK_CLASS_INFO_MENU_CODE,
                        meta: {
                            title: '风险等级详情',
                            breadcrumb: false
                        },
                        hidden: true
                    }
                ]
            },
            {
                path: 'smart-assess-config',
                name: 'SmartAssessConfig',
                meta: {
                    title: '智能评估模型'
                //
                },
                redirect: '/rule-engine/smart-assess-config/list',
                component: RouteLayout,
                code: RULE_ENGINE_SMART_ASSESS_CONFIG_MENU_CODE,
                children: [
                    {
                        path: 'list',
                        name: 'SmartAssessList',
                        component: () => import('./SmartAssessModel/SmartAssessList'),
                        code: RULE_ENGINE_SMART_ASSESS_LIST_MENU_CODE,
                        meta: {
                            title: '智能评估模型',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'info',
                        name: 'SmartAssessInfo',
                        component: () => import('./SmartAssessModel/SmartAssessInfo'),
                        code: RULE_ENGINE_SMART_ASSESS_INFO_MENU_CODE,
                        meta: {
                            title: '智能评估详情',
                            breadcrumb: false
                        },
                        hidden: true
                    }
                ]
            }
        ]
    }
]

export default routes
