/**
 * 审批及日志
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    APPROVE_MANAGEMENT_MENU_CODE,
    RULE_APPROVE_LIST_MENU_CODE,
    RULE_APPROVE_INFO_MENU_CODE,
    COMMODITY_APPROVE_LIST_MENU_CODE,
    COMMODITY_APPROVE_INFO_MENU_CODE,
    OPERATION_LOG_LIST_MENU_CODE,
    RULE_LOOK_RISK_ASSESSMENT_CONTENT_MENU_CODE,
    RULE_LOOK_DATA_CHECK_INFO_MENU_CODE,
    RULE_LOOK_GRADE_MODEL_INFO_MENU_CODE,
    RULE_LOOK_RISK_ASSESSMENT_INFO_MENU_CODE,
    RULE_LOOK_SMART_ASSESS_INFO_MENU_CODE,
    RULE_LOOK_RISK_CLASS_INFO_MENU_CODE,
    RULE_LOOK_RULE_SET_INFO_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/approve',
        name: 'Approve',
        redirect: '/approve/rule-approve-list',
        component: BasicLayout,
        code: APPROVE_MANAGEMENT_MENU_CODE,
        sort: 6,
        meta: {
            title: '审批及日志',
            icon: 'audit',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'rule-approve-list',
                redirect: '/approve/rule-approve-list/index',
                name: 'RuleApproveList',
                component: RouteLayout,
                code: RULE_APPROVE_LIST_MENU_CODE,
                meta: {
                    title: '规则审核'
                },
                // hidden: true,
                children: [
                    {
                        path: 'index',
                        name: 'RuleApproveListIndex',
                        component: () => import('./RuleApprove/RuleApproveList'),
                        code: RULE_APPROVE_LIST_MENU_CODE,
                        meta: {
                            title: '规则审核',
                            breadcrumb: false
                        },
                        hidden: true
                    },
                    {
                        path: 'info',
                        name: 'RuleApproveListInfo',
                        component: () => import('./RuleApprove/RuleApproveInfo'),
                        code: RULE_APPROVE_INFO_MENU_CODE,
                        meta: {
                            title: '规则审核详情',
                            breadcrumb: true
                        },
                        hidden: true
                    },
                    {
                        path: 'risk-class-info',
                        name: 'RuleApproveRiskClassInfo',
                        component: () => import('@views/RuleEngine/RiskClass/RiskClassInfo'),
                        code: RULE_LOOK_RISK_CLASS_INFO_MENU_CODE,
                        meta: {
                            title: '风险等级详情',
                            breadcrumb: false
                        },
                        hidden: true
                    },
                    {
                        path: 'assessment',
                        name: 'RuleApproveRiskAssessmentContent',
                        component: () => import('@views/RuleEngine/RiskAssessmentModel/AddOrEditRiskAssessmentContent'),
                        code: RULE_LOOK_RISK_ASSESSMENT_CONTENT_MENU_CODE,
                        meta: {
                            title: '查看风险评估内容',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            }, {
                path: 'commodity-approve-list',
                redirect: '/approve/commodity-approve-list/index',
                name: 'CommodityApproveList',
                component: RouteLayout,
                code: COMMODITY_APPROVE_LIST_MENU_CODE,
                meta: {
                    title: '商品审核'
                },
                // hidden: true,
                children: [
                    {
                        path: 'index',
                        name: 'CommodityApproveListIndex',
                        component: () => import('./CommodityApprove/CommodityApproveList'),
                        code: COMMODITY_APPROVE_LIST_MENU_CODE,
                        meta: {
                            title: '商品审核',
                            breadcrumb: false
                        },
                        hidden: true
                    },
                    {
                        path: 'info',
                        name: 'CommodityApproveInfo',
                        component: () => import('./CommodityApprove/CommodityApproveInfo'),
                        code: COMMODITY_APPROVE_INFO_MENU_CODE,
                        meta: {
                            title: '商品详情',
                            breadcrumb: true
                        },
                        hidden: true
                    },
                    {
                        path: 'rule-set-info',
                        name: 'ApproveRuleSetInfo',
                        component: () => import('@views/RuleEngine/RuleSet/RuleSetInfo'),
                        code: RULE_LOOK_RULE_SET_INFO_MENU_CODE,
                        meta: {
                            title: '规则集详情'

                        },
                        hidden: true
                    },
                    {
                    // /approve/commodity-approve-list/approve-data-check-info
                        path: 'approve-data-check-info',
                        name: 'ApproveDataCheckInfo',
                        component: () => import('@views/RuleEngine/DataCheckConfig/DataCheckInfo'),
                        code: RULE_LOOK_DATA_CHECK_INFO_MENU_CODE,
                        meta: {
                            title: '数据核验模型详情'

                        },
                        hidden: true
                    },
                    {
                        path: 'approve-grade-model-info',
                        name: 'ApproveGradeModelInfo',
                        component: () => import('@views/RuleEngine/GradeModel/GradeModelInfo'),
                        code: RULE_LOOK_GRADE_MODEL_INFO_MENU_CODE,
                        meta: {
                            title: '评分模型详情'

                        },
                        hidden: true
                    },
                    {
                        path: 'approve-risk-assess-info',
                        name: 'ApproveRiskAssessmentInfo',
                        component: () => import('@views/RuleEngine/RiskAssessmentModel/RiskAssessmentInfo'),
                        code: RULE_LOOK_RISK_ASSESSMENT_INFO_MENU_CODE,
                        meta: {
                            title: '风险评估模型详情'

                        },
                        hidden: true
                    },
                    {
                        path: 'approve-smart-assess-info',
                        name: 'ApproveSmartAssessInfo',
                        component: () => import('@views/RuleEngine/SmartAssessModel/SmartAssessInfo'),
                        code: RULE_LOOK_SMART_ASSESS_INFO_MENU_CODE,
                        meta: {
                            title: '智能评估模型详情'

                        },
                        hidden: true
                    }
                ]
            }, {
                path: 'operation-log-list',
                name: 'OperationLogList',
                component: () => import('./OperationLog/OperationLogList'),
                code: OPERATION_LOG_LIST_MENU_CODE,
                meta: {
                    title: '操作日志'
                }
            }
        ]
    }
]

export default routes
