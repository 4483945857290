/**
 * 风控报告管理
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    RISK_CONTROL_BEFORE_REPORT_MENU_CODE,
    RISK_CONTROL_BEFORE_REPORT_LIST_MENU_CODE,
    RISK_CONTROL_BEFORE_REPORT_INFO_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/risk-control-report',
        name: 'RiskControlReport',
        // redirect: '/risk-control-report/risk-control-report-list/index',
        component: BasicLayout,
        code: RISK_CONTROL_BEFORE_REPORT_MENU_CODE,
        sort: 8,
        meta: {
            title: '风控报告管理',
            icon: 'report',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'risk-control-report-before-list',
                redirect: '/risk-control-report/risk-control-report-before-list/list',
                name: 'RiskControlReportConfig',
                component: RouteLayout,
                code: RISK_CONTROL_BEFORE_REPORT_LIST_MENU_CODE,
                meta: {
                    title: '保前报告'
                },
                children: [
                    {
                        path: 'list',
                        name: 'RiskControlBeforeReportList',
                        component: () => import('./InsureBeforeReport/List'),
                        code: RISK_CONTROL_BEFORE_REPORT_LIST_MENU_CODE,
                        meta: {
                            title: '保前报告',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'info',
                        name: 'RiskControlBeforeReportInfo',
                        component: () => import('./InsureBeforeReport/Info'),
                        code: RISK_CONTROL_BEFORE_REPORT_INFO_MENU_CODE,
                        meta: {
                            title: '保前报告详情',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            }
        ]
    }
]

export default routes
