/**
 * 保前订单
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    ORDER_MENU_CODE,
    ORDER_LIST_MENU_CODE,
    ORDER_INFO_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/order-management',
        name: 'Order',
        redirect: '/order-management/order-list/index',
        component: BasicLayout,
        code: ORDER_MENU_CODE,
        sort: 7,
        meta: {
            title: '订单管理',
            icon: 'order',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'order-list',
                redirect: '/order-management/order-list/index',
                name: 'OrderList',
                component: RouteLayout,
                code: ORDER_LIST_MENU_CODE,
                meta: {
                    title: '保前订单'
                },
                // hidden: true,
                children: [
                    {
                        path: 'index',
                        name: 'OrderListIndex',
                        component: () => import('./OrderList'),
                        code: ORDER_LIST_MENU_CODE,
                        meta: {
                            title: '保前订单',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'info',
                        name: 'OrderListInfo',
                        component: () => import('./OrderInfo'),
                        code: ORDER_INFO_MENU_CODE,
                        meta: {
                            title: '订单详情',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            }
        ]
    }
]

export default routes
