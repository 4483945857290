/**
 * 商品管理
 */
import BasicLayout from '@layout/BasicLayout'
import RouteLayout from '@layout/RouteLayout'

import {
    PRODUCT_MANAGE_MENU_CODE,
    PRODUCT_MANAGE_PRODUCT_CATEGORY_MENU_CODE,
    PRODUCT_MANAGE_PRODUCT_PARAMS_LIST_MENU_CODE,
    PRODUCT_MANAGE_PRODUCT_COMMODITY_LIST_MENU_CODE,
    PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE,
    PRODUCT_MANAGE_PRODUCT_ADD_COMMODITY_MENU_CODE
} from '@constant/menu_code'

const routes = [
    {
        path: '/commodity-manage',
        name: 'CommodityManage',
        redirect: '/commodity-manage/product-category-list',
        component: BasicLayout,
        code: PRODUCT_MANAGE_MENU_CODE,
        sort: 4,
        meta: {
            title: '商品管理',
            icon: 'commodity',
            iconType: 'iconfont'
        },
        children: [
            {
                path: 'product-category-list',
                name: 'ProductCategoryList',
                component: () => import('./ProductCategory'),
                code: PRODUCT_MANAGE_PRODUCT_CATEGORY_MENU_CODE,
                meta: {
                    title: '产品分类'

                }
            }, {
                path: 'params-list',
                name: 'ParamsList',
                component: () => import('./ParamsList'),
                code: PRODUCT_MANAGE_PRODUCT_PARAMS_LIST_MENU_CODE,
                meta: {
                    title: '参数列表'

                }
            }, {
                path: 'commodity-config',
                name: 'CommodityConfig',
                component: RouteLayout,
                redirect: '/commodity-manage/commodity-config/list',
                code: PRODUCT_MANAGE_PRODUCT_COMMODITY_LIST_MENU_CODE,
                meta: {
                    title: '保前商品'

                },
                children: [
                    {
                        path: 'list',
                        name: 'CommodityList',
                        component: () => import('./CommodityConfig/CommodityList'),
                        code: PRODUCT_MANAGE_PRODUCT_COMMODITY_LIST_MENU_CODE,
                        meta: {
                            title: '保前商品',
                            breadcrumb: false
                        },
                        hidden: true
                    }, {
                        path: 'commodity-info',
                        name: 'CommodityInfo',
                        component: () => import('./CommodityConfig/CommodityInfo'),
                        code: PRODUCT_MANAGE_PRODUCT_COMMODITY_INFO_MENU_CODE,
                        meta: {
                            title: '商品详情',
                            breadcrumb: true
                        },
                        hidden: true
                    }, {
                        path: 'add-commodity',
                        name: 'AddCommodity',
                        component: () => import('./CommodityConfig/AddCommodity'),
                        code: PRODUCT_MANAGE_PRODUCT_ADD_COMMODITY_MENU_CODE,
                        meta: {
                            title: '添加商品',
                            breadcrumb: true
                        },
                        hidden: true
                    }
                ]
            }
        ]
    }
]

export default routes
