import { TOKEN_KEY } from '@config'

/**
 * 获取token
 * @returns {*}
 */
export function getToken() {
    return window.localStorage.getItem(TOKEN_KEY)
}

/**
 * 设置token
 * @param token
 * @returns {*}
 */
export function setToken(token) {
    return window.localStorage.setItem(TOKEN_KEY, token)
}

/**
 * 删除token
 * @returns {*}
 */
export function removeToken() {
    return window.localStorage.removeItem(TOKEN_KEY)
}
