/**
 * base module
 * @type {string}
 */
export const SET_USER_INFO = 'SET_USER_INFO'

export const SET_TOKEN = 'SET_TOKEN'

export const SET_ROUTES = 'SET_ROUTES'

export const SET_ROLE = 'SET_ROLE'

export const SET_BUTTON_ROLE = 'SET_BUTTON_ROLE'


/**
 * setting module
 * @type {string}
 */
export const SET_IS_MOBILE = 'SET_IS_MOBILE'

export const SET_THEME = 'SET_THEME'

export const SET_LAYOUT = 'SET_LAYOUT'

export const SET_MULTIPAGE = 'SET_MULTIPAGE'

export const SET_FIXED_HEADER = 'SET_FIXED_HEADER'

export const SET_FIXED_SIDEBAR = 'SET_FIXED_SIDEBAR'

export const SET_REVIEW_OPERATION = 'SET_REVIEW_OPERATION'


export const SET_SYSTEM_NAME = 'SET_SYSTEM_NAME'

export const SET_COPYRIGHT = 'SET_COPYRIGHT'

export const SET_LICENSE_NUMBER = 'SET_LICENSE_NUMBER'

export const SET_MESSAGE_NUM = 'SET_MESSAGE_NUM'
